import { graphql, useStaticQuery } from "gatsby"

const useCities = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { published: { eq: true }, templateKey: { eq: "city" } }
        }
      ) {
        nodes {
          id
          frontmatter {
            slug
            templateKey
            title
            summary {
              display_name
              country_name
              population
              census_year
            }
            latitude
            longitude
            section_approach {
              explicit_strategy
              overall_approach
            }
            section_policies {
              priority_policies
              priority_policies_other
            }
            section_staff {
              skills
              skills_other
            }
            section_funding {
              dedicated_funds
            }
            section_activities {
              activities
              experience {
                data_driven
                digital_tech
                engaging_residents
                human_centered
                org_change
                rethinking
                taking_risks
              }
            }
            section_funding_sources {
              text_additional
            }
            section_funded_activities {
              funded_activities
            }
            section_top_areas_data {
              improve_internal_operations
              create_cost_savings
              anticipate_future_challenges
              service_current_obligations
              improve_service_delivery
              improve_resident_outcomes
              simplify_admin_procedures
              generate_new_revenue_sources
              engage_stakeholders
            }
            section_lacking_factors_data {
              dedicated_funding
              strong_focus_on_data
              a_strong_team
              human_resources
              leadership_commitment
              culture_of_innovation
              engagement_with_partners
              support_from_broader_community
            }
            section_top_challenges_data {
              lack_of_reliable_data
              public_distrust_of_government_data
              lack_of_staff_capacity
              lack_of_technical_infrastructure
              lack_of_compatible_data
              data_are_not_shared
              insufficient_interaction_with_data
              data_priority
            }
            section_current_vs_planned_data {
              current {
                advertising
                stuff
                data_work_decision_making
                outcome_evaluation
              }
              planned {
                advertising
                stuff
                data_work_decision_making
                outcome_evaluation
              }
            }
            section_budget_plans_data
            section_satisfaction_data {
              percent_satisfied
              innovation_staff
              formal_innovation_strategy
              holistic_approach
            }
            section_walkability_data {
              psi_capacity
              walkability
              transport_satisfaction
            }
            section_stakeholder_data {
              open_data_level
              stakeholder_level
              percent_households
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.nodes.map(city => city.frontmatter)
}

export default useCities
