import styled from "styled-components"

const LocationPin = styled.div`
  --pin-color: ${({ theme }) => theme.grey1};
  &.green, &.green:after {
    --pin-color: ${({ theme }) => theme.greenBlue};
  }

  &.blue, &.blue:after {
    --pin-color: ${({ theme }) => theme.darkBlue};
  }

  &.purple, &.purple:after {
    --pin-color: ${({ theme }) => theme.purple};
  }

  &.dark-blue, &.dark-blue:after {
    --pin-color: rgb(55, 122, 159);
  }

  &.light-green, &.light-green:after {
    --pin-color: rgb(130, 182, 132);
  }

  &.pink, &.pink:after {
    --pin-color: rgb(220, 79, 109);
  }

  &.dark-green, &.dark-green:after {
    --pin-color: rgb(78, 133, 104);
  }

  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  box-sizing: content-box;
  cursor: pointer;
  background: white;
  border: 8px solid var(--pin-color);

  &:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    cursor: pointer;
    border-top: 17px solid var(--pin-color);   
  }

  .map-filter--policies & {
    border: 8px solid ${({ theme }) => theme.purple};

    &:after {
      border-top: 17px solid ${({ theme }) => theme.purple};
    }
  }

  .map-filter--skills & {
    border: 8px solid ${({ theme }) => theme.greenBlue};

    &:after {
      border-top: 17px solid ${({ theme }) => theme.greenBlue};
    }
  }

  .map-filter--activities & {
    border: 8px solid ${({ theme }) => theme.darkBlue};

    &:after {
      border-top: 17px solid ${({ theme }) => theme.darkBlue};
    }
  }

  .map-filter--population & {
    border: 8px solid ${({ theme }) => theme.pink};

    &:after {
      border-top: 17px solid ${({ theme }) => theme.pink};
    }
  }

  &.no-cursor {
    cursor: initial;
    &:after {
      cursor: initial;
    }
  }
`

export default LocationPin
